/* Our main Stimulus application singleton instance */

import { Application, ControllerConstructor, defaultSchema } from 'stimulus'

// Custom key mappings
const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, backspace: 'Backspace' },
}

//-- Initialize stimulus --
export const application = Application.start(
  document.documentElement,
  customSchema
)

const registeredIdentifiers: Record<string, boolean> = {}

//-- A helper around application.register that only registers the controller if it hasn't been registered already --
export function registerController(
  identifier: string,
  ControllerConstructor: ControllerConstructor
) {
  if (registeredIdentifiers[identifier]) {
    // console.log('Controller already registered:', identifier)
    return
  }

  application.register(identifier, ControllerConstructor)
  registeredIdentifiers[identifier] = true
}
